<template>
  <div class="d-flex flex-column fill-height pa-0">
    <v-sheet class="d-flex flex-row justify-center align-center">
      <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <h3 class="ma-2 text-center" style="width: 150px">{{ current }}</h3>
      <v-btn icon class="ma-2" @click="$refs.calendar.next()">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>

    <v-sheet class="fill-height align-self-stretch">
      <v-calendar
        ref="calendar"
        v-model="value"
        :weekdays="[1, 2, 3, 4, 5, 6, 0]"
        type="month"
        color="primary"
        @click:date="selectDate"
        @moved="moveCalendar"
      >
        <template v-slot:day="{ date }">
          <v-card
            v-if="events[date]"
            @click="selectDate({ date })"
            clickable
            :color="events[date].ground === 'HILTESTING' ? 'blue' : 'primary'"
            class="pa-1"
          >
            <div class="text-overline event">{{ events[date].ground }}</div>
            <div class="event">{{ events[date].title }}</div>
          </v-card>
          <v-card
            v-else-if="isMod"
            @click="selectDate({ date })"
            clickable
            color="transparent"
            height="calc(100% - 44px)"
          >
            <v-card-text class="text-center">
              <v-icon style="opacity: 0.4">mdi-plus</v-icon>
            </v-card-text>
          </v-card>
        </template>
      </v-calendar>
    </v-sheet>

    <v-dialog v-model="showNewDayDialog">
      <v-card>
        <v-card-title>Create a new Day</v-card-title>
        <v-card-text>
          <testing-days-data-form :date="selectedDate" @submit="createDay">Create</testing-days-data-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DateTime } from 'luxon'

import TestingDaysDataForm from '@components/testingDays/DataForm.vue'

import { TestingDays, CreateTestingDay } from '@graphql/TestingDays.gql'
import { UserInfo } from '@graphql/Users.gql'

export default {
  name: 'TestingPage',
  components: { TestingDaysDataForm },

  mounted() {
    // Add the @keydown event listener to the document
    document.addEventListener('keydown', this.handleKeyDown)
  },
  beforeUnmount() {
    // Remove the @keydown event listener when the component is unmounted
    document.removeEventListener('keydown', this.handleKeyDown)
  },

  apollo: {
    testingDays: {
      query: TestingDays
    },
    loggedUser: { query: UserInfo }
  },
  data() {
    const now = DateTime.now()
    return {
      value: '',
      current: now.toFormat('MMMM yyyy'),
      testingDays: [],
      selectedDate: undefined,
      showNewDayDialog: false,
      tooltip: false,
      loggedUser: undefined
    }
  },
  watch: {
    '$route.query.month': function () {
      if (this.$route.query.month) {
        //check if month parameter is set
        const date = DateTime.fromISO(this.$route.query.month).toFormat("yyyy-MM-'01'") //add day value
        this.value = date //update current calendar view
        this.current = DateTime.fromISO(this.value).toFormat('MMMM yyyy') //update title "Month 2022"
      } else {
        this.value = DateTime.now().toISODate() //if month parameter is not set, set current month
        this.current = DateTime.fromISO(this.value).toFormat('MMMM yyyy') //update title "Month 2022"
      }
    }
  },

  created: function () {
    if (this.$route.query.month) {
      //check if month parameter is set
      const date = DateTime.fromISO(this.$route.query.month).toFormat("yyyy-MM-'01'") //add day value
      this.value = date //update current calendar view
      this.current = DateTime.fromISO(this.value).toFormat('MMMM yyyy') //update title "Month 2022"
    } else {
      this.value = DateTime.now().toISODate() //if month parameter is not set, set current month
      this.current = DateTime.fromISO(this.value).toFormat('MMMM yyyy') //update title "Month 2022"
    }
  },

  methods: {
    moveCalendar() {
      this.current = DateTime.fromISO(this.value).toFormat('MMMM yyyy') //updates calendar title
      const query = {}
      query.month = DateTime.fromISO(this.value).toFormat('yyyy-MM') // add year and month to querys
      this.$router.push({ name: 'Testing', query: query }) //add query parameter day to end of url
    },
    selectDate({ date }) {
      if (!this.testingDays.some((d) => d.date === new Date(date).toISOString())) {
        if (!this.isMod) return
        this.selectedDate = date
        this.showNewDayDialog = true
        return
      }
      this.$router.push({ name: 'TestingDay', params: { date } })
    },
    createDay(data) {
      const variables = {
        data: {
          title: data.title,
          date: this.selectedDate,
          ground: data.ground,
          carId: data.carId,
          baseSetupId: data.baseSetupId,
          responsibleId: data.responsibleId
        }
      }
      this.$apollo
        .mutate({ mutation: CreateTestingDay, variables })
        .then(() => {
          this.$router.push({ name: 'TestingDay', params: { date: this.selectedDate } })
        })
        .catch((res) => {
          this.$store.commit('openErrorDialog', res)
        })
    },
    handleKeyDown(event) {
      // Check if the left arrow key is pressed (key code 37)
      if (event.keyCode === 37) {
        console.log('left')
        // Call the $refs.calendar.prev() function
        this.$refs.calendar.prev()
      } else if (event.keyCode === 39) {
        // Call the $refs.calendar.prev() function
        this.$refs.calendar.next()
      }
    }
  },
  computed: {
    events() {
      return Object.fromEntries(this.testingDays.map((day) => [DateTime.fromISO(day.date).toISODate(), day]))
    },
    isMod() {
      if (!this.loggedUser) return false
      if (this.loggedUser.role !== 'MODERATOR' && this.loggedUser.role !== 'ADMIN') return false
      return true
    }
  }
}
</script>

<style scoped>
.event {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
}
.text-overline.event {
  height: 20px;
}
</style>
